<template>
  <div class="Enter-box">
    <p class="Enterprise-p"><i></i><span>云盘 / 续费</span></p>
    <div class="Enterprise">
      <div class="Enterprise-title">
        <span></span>
        <!-- <p>企业信息</p> -->
      </div>
      <div class="Enterprise-payment">
        <div class="Enterprise-state">
          <span @click="affirm">确定订单</span>
          <span>支付</span>
          <span>支付完成</span>
        </div>
        <div class="Enterprise-unpaid">
          <span>待支付</span>
          <p><span>￥</span>{{ money }}</p>
        </div>
        <div class="Enterprise-payment-box">
          <div class="Enterprise-gathering" @click="pay">
            <img src="../../assets/img/erweima.png" alt="" />
            <p>支付宝支付</p>
          </div>
          <div class="Enterprise-gathering" id="flow-img" @click="pay">
            <div ref="qrCodeUrl"></div>
            <!-- <img src="../../../assets/img/erweima.png" alt="" /> -->
            <!-- <img src="weixin://wxpay/bizpayurl?pr\u003dhQQejsRzz" id="flow-img2" alt="" height="100px" /> -->
            <p>微信支付</p>
          </div>
          <div class="Enterprise-payment-transfer">
            <p class="Enterprise-s">银行转账</p>
            <div>
              <span>收款户名：</span>
              <el-input
                id="copy"
                v-model="gathering_bank"
                :value="this.gathering_bank"
                placeholder=""
                disabled
              ></el-input>
            </div>
            <div>
              <span>开户银行：</span>
              <el-input
                class=""
                v-model="gathering_name"
                placeholder=""
                disabled
              ></el-input>
            </div>
            <div>
              <span>收款账号：</span>
              <el-input
                class=""
                v-model="gathering_account"
                placeholder=""
                disabled
              ></el-input>
            </div>
            <img id="docimg" src="" alt="" />
            <p class="Enterprise-p">到账时间以银行为准，一般1-3个工作日</p>
            <a @click="copy('e')" class="Enterprise-copy"> 复制全部 </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_wxpay_qrcode, lun_xun_trade_no, insert_order_web} from "@api/pulic";

import { insert_order_net_disk } from '../../api/store'


import QRCode from "qrcodejs2";

export default {
  data() {
    return {
      numS: 1,
      money: 13000,
      gathering_bank: "北京餐亿本科技有限公司",
      gathering_account: "123456789876543212",
      gathering_name: "北京建设银行丰台支行",
      qrCodeSrc: "", // 空着的
      outTradeNo:'', // 唯一的二维码id
      setIntervalName:null, // 定时器 名称
    };
  },
  mounted() {
    this.setIntervalF()
    this.router_cc()
    this.get_wxpay_qrcode();


    // this.lun_xun_trade_no();
  },
  methods: {

    router_cc(){
      let item = this.$route.params.item
      let itemobj = JSON.parse(item)
      console.log(itemobj);
      this.money = itemobj.pay_amt
    },


    affirm(){
      console.log(11111111)
      this.$router.push('/EnterpriseConfirm')
    },

    pay() {
      // this.insert_order_net_disk()
    },

     copy() {
        var input = document.createElement("input");   // js创建一个input输入框
        input.value = this.gathering_bank + "," + this.gathering_account  + "," + this.gathering_name;  // 将需要复制的文本赋值到创建的input输入框中，this.ruleForm.url这个是我要复制的内容
        document.body.appendChild(input);    // 将输入框暂时创建到实例里面
        input.select();   // 选中输入框中的内容
        document.execCommand("Copy");   // 执行复制操作
        document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
        this.$message({
          message: '复制成功',
          type: 'success'
        });
    },


    insert_order_net_disk(){
      let item = this.$route.params.item
      let data_li =  JSON.parse(item)
      console.log(data_li);
      data_li.ent_id = this.$store.state.ent_id
      data_li.order_id = this.outTradeNo
      data_li.size_net = (data_li.num*1).toString()
      data_li.years = (data_li.numS*1).toString()

      delete data_li.num
      delete data_li.numS

      insert_order_net_disk({data:data_li}).then((res)=>{
        if (res.code=='200') {
          this.$router.push("/Clickyunok");
        }
      })

    },

    test() {
      // 0-9的随机数
      var arr = []; //容器
      for (var i = 0; i < 6; i++) {
        //循环六次
        var num = Math.random() * 9; //Math.random();每次生成(0-1)之间的数;
        num = parseInt(num, 10);
        arr.push(num);
      }
      return arr.join("");
    },


    get_wxpay_qrcode() {
      let newHM_time = Date.now();
      let newSuiJi = this.test();
      console.log("随机数", String(newHM_time) + newSuiJi);
      let outTradeNo = "wx" + String(newHM_time) + newSuiJi;
      this.outTradeNo = JSON.parse(JSON.stringify(outTradeNo))

      let totalFee =this.money //"1";
      let data = {
        data: {
          outTradeNo: outTradeNo, // 唯一的
          totalFee: String(totalFee*1*100), // 金额
          productId: "null",
        },
      };

      get_wxpay_qrcode(data).then((res) => {
        console.log(res);
        // responseType为arraybuffer
        console.log(res);

        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: res.body.data, // 需要转换为二维码的内容
          width: 100,
          height: 100,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });

      });
    },


    setIntervalF(){
          // let a = 0
        this.setIntervalName = setInterval(() => {
          console.log('1');
          // a += 1
          this.lun_xun_trade_no()
        }, 1000);
    },

    lun_xun_trade_no(){
      let outTradeNo = this.outTradeNo
      let data = {data:{
        outTradeNo:outTradeNo
      }}
      lun_xun_trade_no(data).then(res=>{
        console.log(res);
        if (res.body.data.pay_status == 1) {
          this.insert_order_net_disk()
          clearInterval(this.setIntervalName)
        }
      })
    },
  },
  beforeUpdate(){},
  beforeDestroy(){
    console.log('注销==========');
    clearInterval(this.setIntervalName)
    this.setIntervalName = null
  },
};
</script>

<style scoed lang="scss">
.el-main {
  background: #f6f6fa;
}
.Enterprise-p {
  font-size: 18px;
  color: #999999;
  display: flex;
  margin: 26px 0 30px 0;
  span {
    margin: 4px;
  }
  i {
    background: url("../../assets/img/din.png") no-repeat;
    width: 23px;
    height: 30px;
    display: block;
    margin-right: 11px;
  }
}
.Enterprise {
  width: 100%;
  background: #fefefe;
  -moz-box-shadow: 0px 0px 10px #e8e8eb;
  -webkit-box-shadow: 0px 0px 10px #e8e8eb;
  box-shadow: 0px 0px 10px #e8e8eb;
  margin-bottom: 40px;
  .Enterprise-payment {
    padding: 22px 34px 151px;
    box-sizing: border-box;
    .Enterprise-state {
      background: url("../../assets/img/zhifu.png");
      width: 1054px;
      height: 34px;
      display: block;
      margin: 0 auto 44px auto;
      display: flex;
      span {
        line-height: 34px;
        color: #fff;
        font-size: 14px;
        width: 33%;
        text-align: center;
      }
    }
    .Enterprise-unpaid {
      padding: 60px 0 102px 0;
      & > span {
        font-size: 20px;
        color: #333333;
        text-align: center;
        display: block;
        margin-bottom: 30px;
      }
      p {
        font-size: 24px;
        color: #333333;
        text-align: center;
      }
    }
    .Enterprise-payment-box {
      display: flex;
      justify-content: space-between;
      width: 1260px;
      margin: 0 auto;
      .Enterprise-gathering {
        width: 180px;
        img {
          display: block;
          margin: 0 auto;
          width: 100%;
        }
        p {
          color: #333333;
          font-size: 16px;
          text-align: center;
          margin-top: 30px;
        }
      }
    }
    .Enterprise-payment-transfer {
      & > div {
        display: flex;
        margin-bottom: 20px;
      }
      .Enterprise-s {
        color: #333;
        font-size: 16px;
        width: 80px;
        padding-bottom: 12px;
      }
      .Enterprise-p {
        margin: 10px 31px;
        color: #999;
        font-size: 13px;
        text-align: right;
      }
      span {
        font-size: 16px;
        color: #333;
        width: 116px;
        margin: 14px 0px 0 0;
        font-weight: 600;
      }
      input {
        width: 282px;
        height: 40px;
        border: 0;
        color: #333333;
        border-radius: 10px;
        &::placeholder {
          color: #333333;
        }
      }
      .Enterprise-copy {
        color: #648cc8;
        font-size: 13px;
        display: block;
        text-align: center;
      }
    }
  }
}
</style>
